import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import * as scrollToElement from 'scroll-to-element';

import LiftButton from './LiftButton';


class FloorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animatedButtons: [],
      scrollPosition: 0,
      hasScroll: false,
    }
  }

  componentDidMount() {
    this.runAnimation();
    const root = document.getElementById('root');
    const pageHeight = root.offsetHeight;
    const viewHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    if(pageHeight < viewHeight) {
      this.setState({hasScroll: false});
    } else {
      this.setState({hasScroll: true});
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  componentDidUpdate(oldProps, prevState) {
    if(this.state.animatedButtons.length > 0 && this.state.animatedButtons.length !== prevState.animatedButtons.length) {
      const element = document.getElementById('root');
      const areas = this.props.areas.flat();
      const scrollTime = areas.length*75*1;
      scrollToElement(element, {
        duration: scrollTime,
        align: 'bottom',
        ease: 'linear',
      });
      window.addEventListener('scroll', this.listenToScroll);
    }
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolled = winScroll / height;
    if(scrolled > this.state.scrollPosition + 0.05 || scrolled < this.state.scrollPosition - 0.05) {
      this.setState({
        scrollPosition: scrolled,
      });
    }
  }

  runAnimation() {
    //highlight rows in an order
    let index = 0;
    const areas = this.props.areas;
    const buttons = areas.flat();
    const animatedButtons = [];
    const interval = setInterval(() => {
      animatedButtons.push(buttons[index]);
      this.setState({animatedButtons});
      index++;
      if(index > buttons.length) {
        setTimeout(() => {
          this.setState({animatedButtons: []})
        }, 500);
        clearInterval(interval);
      }
    }, 75)
  }

  render() {
    const {
      areas,
      handleChange,
      myArea,
      destinationArea,
    } = this.props;
    const upOpacity = 1 - this.state.scrollPosition;
    const downOpacity = this.state.scrollPosition;
    return (<>
      <div>
        {this.state.hasScroll &&
          <div className="scroll-buttons">
            <div className="up-arrow" style={{opacity: upOpacity }}>
            </div>
            <div className="down-arrow" style={{opacity: downOpacity }}>
            </div>
          </div>
        }
        {areas.map((row, ri) => (
          <Row key={ri} className={`floor-row`}>
            {row.map((col, ci) => (
              <Col key={ci} className={`m-1 embed-responsive embed-responsive-1by1`}>
                <div className={`svg-helper ${this.state.animatedButtons.includes(col) ? "animated-bg" : ''} ${col === myArea ? 'current-floor-helper' : ''}`}>
                  <LiftButton
                    key={ci}
                    myArea={myArea}
                    floor={col}
                    onClick={handleChange}
                    destinationArea={destinationArea}
                  />
                </div>
              </Col>
            ))}
          </Row>
        ))}
      </div>
      <div className="container pb-2">
        <a href="https://www.kone.com/en/privacy-statement-for-kone-qr-call.aspx" alt="Link to privacy statement">
          Privacy statement
        </a>
      </div>
    </>)
  }
}

export default FloorPicker;
