import React, { Component } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { ReactComponent as KoneLogoSpinner } from "./kone-logo.svg";
import { ReactComponent as KonePeopleLogo } from './people-single-nons.svg';
import { ReactComponent as ArrowRight } from './arrow-right-large-white.svg';


class LiftStatusInfo extends Component {
  render() {
    let {
      guidance,
      liftName,
      destinationArea,
      myArea,
    } = this.props;

    const isDone = guidance && guidance !== "wait_for_car"
    const waitingElevatorCall = !isDone;

    return (
      <div className="status-info w-80-center" style={{marginTop: "6rem"}}>
        <Row className="info-row justify-content-center no-gutters slide-in">
          <Col className="col-3">
            <div className="info-current-floor-helper">
              <div className="people-logo-container">
                <KonePeopleLogo className={isDone ? "fade-out" : ""} />
              </div>
              <Button
                color="info"
                className={`px-0 d-flex pt-1 align-items-center justify-content-center font-weight-bold rounded-0 btn-square lift-info-button`}
                style={{fontSize: "2.5rem"}}
                disabled={true}
              >
                {myArea}
              </Button>
            </div>
          </Col>
          <Col className="p-0 col-3">
            <div className={`${waitingElevatorCall ? 'status-arrow-big-animated' : 'status-arrow-big'}`}>
              <ArrowRight />
            </div>
          </Col>
          <Col className="col-3">
            <Button
              color="info"
              className={`px-0 d-flex pt-1 align-items-center justify-content-center font-weight-bold rounded-0 btn-square lift-info-button ${waitingElevatorCall ? 'lift-info-button-animated' : 'lift-info-button-destination'}`}
              style={{fontSize: "2.5rem"}}
              disabled={true}
            >
              {destinationArea}
            </Button>
          </Col>
        </Row>
        {waitingElevatorCall &&
          <div style={{marginTop: "5rem"}}>
            <Row>
              <Col
                className="d-flex align-items-end justify-content-center"
                style={{height: "8rem"}}>
                {liftName &&
                  <span
                    className="text-secondary my-0 slide-in-fast"
                    style={{fontSize: "10rem", lineHeight: 0.72}}
                  >
                    {liftName}
                  </span>
                }
              </Col>
            </Row>
            <Row>
              <Col className="logo-spinner">
                {liftName && <KoneLogoSpinner/> }
              </Col>
            </Row>
          </div>
        }
        {isDone &&
          <div className="name-row" style={{marginTop: "5rem"}}>
            <Row className="justify-content-center no-gutters align-items-end">
              <Col className="col-4">
                <div className="people-logo-container-animated ml-3 float-right">
                  <KonePeopleLogo style={{"verticalAlign": "baseline"}}/>
                </div>
              </Col>
              <Col className="p-0 col-3">
                <div className="ml-n2 status-arrow-small">
                  <ArrowRight />
                </div>
              </Col>
              <Col
                className="col-5 lift-name-slide-side d-flex align-items-end"
                style={{height: "8rem"}}
              >
                <span
                  className="lift-name-text my-0 ml-3"
                  style={{fontSize: "10rem", lineHeight: 0.72}}
                >
                  {liftName}
                </span>
              </Col>
            </Row>
          </div>
        }
      </div>

    )
  }
}


export default LiftStatusInfo;
