import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';

import { ReactComponent as WarningSpacer } from './warningspacer.svg';
import { ReactComponent as ElevatorSvg } from './elevator.svg';
import { ReactComponent as PeopleSvg } from './people-single-white.svg';
import { ReactComponent as ArrowRight } from './arrow-right-small-yellow.svg';
import { ReactComponent as ButtonOff } from './button-off.svg';
import { ReactComponent as TextVpn } from './text-vpn.svg';
import { ReactComponent as TextWifi } from './text-wifi.svg';
import { ReactComponent as ErrorLogo } from './info-circle.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

class ErrorView extends Component {
  refresh = () => {
    window.location.reload();
  }

  render() {
    const {
      active,
      handleRefresh,
      marginTop,
      showRefresh,
      subText,
      text,
    } = this.props;

    const display = active ? "" : "d-none";
    const classes = `${display} text-center text-center error-view`

    if(text === "Too far away from the elevator.") {
      return (
        <div style={{marginTop: "6rem", width: "80%"}} className={classes}>
          <Row className="no-gutters justify-content-center">
            <Col className="people-svg col-4">
              <PeopleSvg />
            </Col>
            <Col className="warningspacer-svg col-4">
              <WarningSpacer />
            </Col>
            <Col className="elevator-svg col-4">
              <ElevatorSvg />
            </Col>
          </Row>
          <Row className="text-primary"style={{marginTop: "2rem"}}>
            <Col>
              <h4>Too far from elevator</h4>
            </Col>
          </Row>
          <Row className="justify-content-center no-gutters" style={{marginTop: "2rem"}}>
            <hr className="col error-divider" />
            <Col className="col-2 error-icon">
              <ErrorLogo />
            </Col>
            <hr className="col error-divider" />
          </Row>
          <Row className="mt-4 text-primary">
            <Col>
              <h4>Try the following</h4>
            </Col>
          </Row>
          <Row className="no-gutters mt-3">
            <Col className="people-svg col-4">
              <TextVpn />
            </Col>
            <Col className="warningspacer-svg col-4">
              <ArrowRight />
            </Col>
            <Col className="elevator-svg col-4">
              <ButtonOff />
            </Col>
          </Row>
          <Row className="no-gutters">
            <Col className="people-svg col-4">
              <TextWifi />
            </Col>
            <Col className="warningspacer-svg col-4">
              <ArrowRight />
            </Col>
            <Col className="elevator-svg col-4">
              <ButtonOff />
            </Col>
          </Row>
        </div>
      )
    }

    return (
      <div style={{marginTop: marginTop || "50%"}} className={classes}>
        <Row className="mb-2">
          <Col>
            <FontAwesomeIcon className="text-warning" icon={faExclamationTriangle} />
          </Col>
        </Row>
        <Row className="">
          <Col>
            <h4 className="text-dark m-0">{text}</h4>
          </Col>
        </Row>
        <Row className="">
          <Col>
            <p>{subText}</p>
          </Col>
        </Row>
        {showRefresh &&
          <Row className="mt-4">
            <Col>
              <Button
                color="secondary"
                onClick={handleRefresh || this.refresh}
                className="mt-5 px-4 py-1"
                style={{ fontSize: "1.2rem" }}
              >
                Refresh
              </Button>
            </Col>
          </Row>
        }
      </div>
    )
  }
}


export default ErrorView;
