import React, { Component } from 'react';
import queryString from 'query-string';

import {
  postCall,
  getBuildingData,
  getCall,
  getLift,
} from './madeb_api';
import { arrayToRows } from './utils';

import ErrorView from './ErrorView';
import FloorPicker from './FloorPicker';
import KoneSpinner from './KoneSpinner';
import LiftStatusInfo from './LiftStatusInfo';
import Survey from './Survey';


const UNKNOWN_LIFT = "";


class App extends Component {

  // Locks for polling
  getLiftNameInProgress = false;
  getCallInProgress = false;
  getCarInProgress = false;
  endingSequenceInProgres = false;

  // Referral token
  token = '';
  prefix = '';

  constructor(props) {
    super(props);

    // Fetch token from GET parameters from the initial redirection
    const { referral } = queryString.parse(window.location.search);
    const { prefix } = queryString.parse(window.location.search);
    this.token = referral;
    this.prefix = prefix;

    this.state = {
      // Display data
      areaListing: [],
      buildingName: '',
      buildingSiteName: '',
      liftName: null,
      myArea: {},
      callStatus: {},

      // Logic
      // TODO: A proper statemachine for each "view" wouldn't hurt here.
      areaError: false,
      areaErrorMessage: "",
      destinationArea: "",
      callId: "",
      callSent: false,
      showEnding: false,
      slideOut: false,
      statusSlideOut: false,
    };
  }

  componentDidMount() {
    this.fetchAreas();
  }

  componentWillUnmount() {
    this.disableCallStatusPolling()
  }

  setDestinationArea = (area) => {
    this.runSelectAnimation(area, this.callElevator);
  }

  runSlideOutAnimation = (area) => {
    this.setState({slideOut: true}, this.waitAnimation(1000, area, this.callElevator));
  }

  runSelectAnimation = (area) => {
    this.setState({destinationArea: area}, this.waitAnimation(2000, area, this.runSlideOutAnimation));
  }

  waitAnimation = (time, arg, callback) => {
    setTimeout(() => callback(arg), time);
  }

  fetchLiftName = () => {
    if (this.getLiftNameInProgress === true) {
      // Block extra calls if one is already pending.
      console.log("Lift already fetching!")
      return
    }

    const { callId } = this.state;

    this.getLiftNameInProgress = true;
    getLift(this.token, this.prefix, callId)
      .then((data) => {
        this.setState({ liftName: data.name });
      })
      .catch(() => {
        this.setState({ liftName: UNKNOWN_LIFT });
      })
      .finally(() => {
        this.getLiftNameInProgress = false;
      });
  }

  fetchAreas = () => {
    // Disable error so we can refresh from the error view.
    this.setState({ areaError: false })

    getBuildingData(this.token, this.prefix)
      .then((data) => {
        this.setState({
          areaListing: arrayToRows(data.floors, 2),
          myArea: data.currentFloor,
          buildingName: data.name,
          buildingSiteName: data.siteName,
        });
      })
      .catch((error) => {
        // "We are unable to retrieve the building data"
        this.setState({
          areaError: true,
          areaErrorMessage: error,
        });
      });
  }

  callElevator = (destinationArea) => {
    //const { destinationArea } = this.state;
    this.setState({ callSent: true, slideOut: false });

    postCall(this.token, this.prefix, destinationArea)
      .then((data) => {
        const { callId } = data;
        this.setState({ callId })

        this.enableCallStatusPolling()
      });

    // TODO: Error handling. Currently failed requests just leave the user
    // hanging with an infinite spinner.
  }

  isCallDone = (callData) => {
    const { callState, passengerGuidance } = callData;
    return (
      callState === "served" ||
      passengerGuidance === "stay_in_car" ||
      passengerGuidance === "exit_car"
    )
  }

  isCallCancelled = (callData) => {
    const { callState, passengerGuidance } = callData;
    return (
      callState === "cancelled" ||
      passengerGuidance === "call_cancelled"
    )
  }

  fetchCallStatus = () => {
    const { callId } = this.state;

    if (this.getCallInProgress === true) {
      // Block extra calls if one is already pending.
      console.log("Call already fetching!")
      return
    }

    console.log("CALL status")
    this.getCallInProgress = true;
    getCall(this.token, this.prefix, callId)
      .then((data) => {
        if (!this.state.liftName || this.state.liftName === UNKNOWN_LIFT) {
          // TODO: Move to backend?
          this.fetchLiftName();
        }

        const callDone = this.isCallDone(data);
        const callCancelled = this.isCallCancelled(data);

        if (callCancelled) {
          // Stop everything immedietaly, no point in going on.
          this.disableCallStatusPolling()
        }

        if (callDone && !this.endingSequenceInProgress) {
          // Wait at least 5 seconds before stopping everything.
          // The API guidance states don't always seem to be 100% accurate,
          // so we compensate a bit here for usability.
          console.log("Call done in 5sec!");
          this.endingSequenceInProgress = true;

          window.setTimeout(() => {
            console.log("Call totally done now!");
            this.setState({statusSlideOut: true});
            window.setTimeout(() => {
              this.setState({ showEnding: true, statusSlideOut: false });
            }, 900);
            this.disableCallStatusPolling();
          }, 5000)
        }

        this.setState({ callStatus: data })
      })
      .finally(() => {
        this.getCallInProgress = false;
      })
  }

  disableCallStatusPolling = () => {
    console.log("Disabling call polling...")
    window.clearInterval(this.poller);
    this.poller = null;
  }


  enableCallStatusPolling = () => {
    console.log("Enabling call polling...")
    this.fetchCallStatus()
    this.poller = window.setInterval(
      () => this.fetchCallStatus(),
      2000
    );
  }

  render() {
    const {
      areaError,
      areaErrorMessage,
      areaListing,
      callId,
      callSent,
      callStatus,
      destinationArea,
      liftName,
      myArea,
      showEnding,
    } = this.state;

    const areasLoaded = !!areaListing.length;
    const showFloorPicker = areasLoaded && !callSent;
    const showLiftStatus = callSent && !showEnding;
    return (
      <div className="container p-0 text-center">
        <header className="header fixed-top container py-1">
          <div>KONE - QR Call</div>
        </header>
        <div className={`container p-0 ${this.state.slideOut ? 'slide-out' : ''}  ${this.state.statusSlideOut ? 'status-slide-out' : ''}`}>
          <KoneSpinner
            active={!areasLoaded && !areaError}
            text="Loading magic buttons..."
          />
          <ErrorView
            active={areaError}
            text={areaErrorMessage}
            subText="Please try again later"
            showRefresh={true}
            handleRefresh={this.fetchAreas}
          />
          {showFloorPicker &&
            <FloorPicker
              myArea={myArea}
              areas={areaListing}
              destinationArea={destinationArea}
              handleChange={this.setDestinationArea}
            />
          }
          {showLiftStatus &&
            <LiftStatusInfo
              callState={callStatus.callState}
              passengerETA={callStatus.passengerETA}
              guidance={callStatus.passengerGuidance}
              liftName={liftName}
              myArea={myArea}
              destinationArea={destinationArea}
            />
          }
          {showEnding &&
            <Survey token={this.token} prefix={this.prefix} callId={callId} />
          }
        </div>
      </div>
    );
  }
}

export default App;
