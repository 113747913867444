import React from 'react';

import { normalizeFloor } from './utils';

const LiftButton = (props) => {
  const { floor, myArea, disabled, onClick, destinationArea } = props;

  if (floor.dummy) {
    // Rendering hax for the last row, if it's not an even number of buttons
    // we just render missing amount of "nulls".
    return null;
  }

  const selected = floor === destinationArea;

  // This disables also sibling floors, eg. both of: [18, 18R]
  const isSameArea = (
    normalizeFloor(myArea) === normalizeFloor(floor)
  );

  const isMyFloor = (
    myArea === floor
  );

  return (
    <button
      className={`px-0 d-flex pt-2 align-items-center justify-content-center font-weight-bold rounded-0 btn-square lift-button ${disabled || isSameArea ? 'disabled': ''} ${isMyFloor ? 'current-floor' : ''} ${selected ? 'destination-floor' : ''}`}
      disabled={disabled || isSameArea}
      value={floor}
      onClick={() => onClick(floor)}
    >
      {floor}
    </button>
  )
}

export default LiftButton;
