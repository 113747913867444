import React, { Component } from 'react';
import { Button } from 'reactstrap';
import StarRatingComponent from 'react-star-rating-component';

import { ReactComponent as StarHollow } from "./star-hollow.svg";
import { ReactComponent as StarFilled } from "./star-filled.svg";
import { ReactComponent as KoneLogo } from "./kone-logo-blue.svg";
import { ReactComponent as HandShake } from "./hand-shake.svg";

import { postFeedback } from './madeb_api';


class Survey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rating: 0,
      ratingSet: false,
      text: '',
      sent: false,
    };
  }

  setStarRating = (rating) => {
    this.setState({ rating, ratingSet: true });
  }

  setText = (e) => {
    const text = e.target.value;
    this.setState({ text });
  }

  sendFeedback = () => {
    const { token, prefix, callId } = this.props;
    const { rating, text } = this.state;

    // TODO: Spinner, finer control over `sent`
    postFeedback(token, prefix, callId, rating, text)

    this.setState({ sent: true })
  }

  render() {
    const { rating, sent } = this.state;

    return (
      <div className="survey-container" style={{marginTop: "50%"}}>
        {sent && <>
          <div style={{fontSize: "4rem"}} className="slide-in text-primary">
              <HandShake />
          </div>
          <div style={{fontSize: "1.75rem"}} className="slide-in text-primary mt-4">
              Thank you!
          </div>
          <div className="fixed-bottom">
              <KoneLogo style={{maxWidth: "3.5rem"}} />
          </div>
        </>}
        {!sent && <>
          <div style={{fontSize: "2.9rem"}} className="slide-in">
            <StarRatingComponent
              name="callRating"
              editing={!sent}
              onStarClick={this.setStarRating}
              renderStarIcon={ (index, value) => {
                  return index <= value ? <StarFilled /> : <StarHollow />;
              }}
              starCount={5}
              value={rating}
            />
          </div>
          {this.state.ratingSet &&
            <div className="w-80-center slide-in-fast">
              <textarea
                className="survey-textarea"
                placeholder="FEEDBACK"
                onChange={(e) => this.setText(e)}
              >

              </textarea>
              <Button
                disabled={sent}
                className="survey-button w-100"
                color="info"
                onClick={this.sendFeedback}
              >
                ✓
              </Button>
            </div>
          }
        </>}
      </div>
    )
  }
}

export default Survey
