export const normalizeFloor = (shortName) => {
  // For comparing floor names, the R is a hinderance of equality.
  return shortName.replace("R", "");
}


export const arrayToRows = (array, rowLength) => {
  const rows = [];
  for(var i=0; i<array.length; i+=rowLength) {
    const slice = array.slice(i, i+rowLength);

    for (var c=0; slice.length < rowLength; c++) {
      // Fill in the last blanks, so each row is the same length
      // even if we don't have exact amount of content.
      slice.push({"dummy": true})
    }

    rows.push(slice);
  }
  return rows;
}


/*
 * This is for the ButtonPanel, that is not used in Kone's own design.
 */
export const sortAreasByFloors = (areas) => {
  // Create a 2-dimensional array from our 1-dimensional list.
  // Keep an outside counter, because the R's will skew our index otherwise.
  let floor = 0;
  const areasByFloors = [];

  // First pass: Add only the base floors in index 0.
  areas.map((area) => {
    if (!area.shortName.includes("R")) {
      areasByFloors[floor] = [area];
      floor = floor + 1;
    }
    return area;
  });


  // Second pass: Attach the floors marked with "R" to the corresponding base floor.
  areas.map((area) => {
    if (area.shortName.includes("R")) {
      const floorNameR = area.shortName.replace("R", "");
      const sameFloor = areasByFloors.find(
        (value) => {
          const floorName = value[0].shortName;
          return floorNameR === floorName;
        }
      );
      sameFloor.push(area);
    }
    return area;
  })

  return areasByFloors;
}
