import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

import { ReactComponent as KoneLogoSpinner } from "./kone-logo.svg";


class KoneSpinner extends Component {
  render() {
    const { text, active, marginTop } = this.props;

    if (!active) {
      // Just return nothing, animated SVGs won't work otherwise.
      return null;
    }

    return (
      <div style={{marginTop: marginTop || "50%"}} className="text-center">
        <Row className="mb-3">
          <Col className="logo-spinner">
            <KoneLogoSpinner />
          </Col>
        </Row>
        <Row className="text-center my-3">
          <Col>
            <h5>{text}</h5>
          </Col>
        </Row>
      </div>
    )
  }
}

export default KoneSpinner;
