import axios from 'axios';

// Magic Add Digital Elevator Button API. Our backend.
// Define any API functions here.
const madebAPI = axios.create({
  baseURL: process.env.REACT_APP_MADEB_API_URL,
  headers: { common: {
    "Authorization": process.env.REACT_APP_MADEB_API_KEY
  }},
})

const _handleResponse = (response) => {
  const { data } = response;

  if (!data) {
    // TODO: Do something fancier here.
    console.error(`No data received for ${response}`);
    return
  }

  return data;
};

const _handleError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    throw error.response.data.message;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    throw error.message;
  } else {
    // Something happened in setting up the request that triggered an Error
    // eg. Network Error, no connection.
    throw error.message;
  }
};


export const getBuildingData = (token, prefix) => {
  // Filter out empty tokens for cases where you load the page without
  // going through a proper referral link. Just for a nicer error message.
  //const data = token && token.length ? `token=${token}` : "";
  return madebAPI.get("/api/elevator/buildingData", {params: {token, prefix}})
    .then(_handleResponse)
    .catch(_handleError)
};

export const postCall = (token, prefix, destArea) => {
  return madebAPI.post("/api/elevator/call", {token, prefix, destArea})
    .then(_handleResponse)
    .catch(_handleError)
};

export const getCall = (token, prefix, callId) => {
  return madebAPI.get("/api/elevator/call", {params: {token, prefix, callId}})
    .then(_handleResponse)
    .catch(_handleError)
};

export const getLift = (token, prefix, callId) => {
  return madebAPI.get("/api/elevator/lift", {params: {token, prefix, callId}})
    .then(_handleResponse)
    .catch(_handleError)
};

export const getLiftCar = (token, prefix, callId) => {
  return madebAPI.get("/api/elevator/liftCar", {params: {token, prefix, callId}})
    .then(_handleResponse)
    .catch(_handleError)
};

export const postFeedback = (token, prefix, callId, rating, text) => {
  return madebAPI.post("/api/feedback", {token, prefix, callId, rating, text})
    .then(_handleResponse)
    .catch(_handleError)
};

export default madebAPI;
